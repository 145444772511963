import './App.scss';
import HomePage from './pages/HomePage/HomePage'

function App() {
  return (
    <>
      <HomePage />
    </>
  );
}

export default App;
